import React, {useRef, useEffect, useState} from 'react';
import {Loader2, MapPin} from 'lucide-react';

import '../component/CollaborationForm.css';
import { Users } from 'lucide-react';
import Select from 'react-select';

import { SlidersHorizontal, ChevronDown, Check } from 'lucide-react';
import {  ExternalLink } from 'lucide-react';
import SubmitSection from "./SubmitSection";

import { XCircle } from 'lucide-react'; // Import the custom icon


const CollaborationForm = () => {


    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Replace hardcoded data with state
    const [influencerData, setInfluencerData] = useState([]);
    const [samplePages, setSamplePages] = useState([]);

    // Google Apps Script deployed URL
    const SCRIPT_URL = 'https://script.google.com/macros/s/AKfycbyOuM4CmczrhIqax30p4huZCNwOKfLebkoNVL4SnTmXR9nHaYpbBIJuhagctdZNGKCC/exec';

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            setError(null);

            const response = await fetch(`${SCRIPT_URL}?action=getAllData`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            // Check if the response has the expected structure
            if (data.status === 'success' && Array.isArray(data.creators) && Array.isArray(data.collaborations)) {
                // Update state with fetched data
                setInfluencerData(data.creators.map(creator => ({
                    id: creator.id || Math.random().toString(),
                    name: creator.name || '',
                    followers: creator.followers || '0',
                    link: creator.link || '',
                    expertise: creator.expertise || '',
                    category: creator.category || '',
                    charges: creator.charges || 0,
                    location: creator.location || '',
                    profilePicture: creator.profilePicture || "",
                    demo1: creator.demo1 || "",
                    demo2: creator.demo2 || ""
                })));

                setSamplePages(data.collaborations.map(collab => ({
                    id: collab.id || Math.random().toString(),
                    name: collab.name || '',
                    followers: collab.followers || '0',
                    link: collab.link || '',
                    videoUrl: collab.videoUrl || '',
                    charges: collab.charges || 0,
                    category: collab.category || '',
                    location: collab.location || '',
                    profilePicture: collab.profilePicture || "",
                    demo1: collab.demo1 || "",
                    demo2: collab.demo2 || ""
                })));
            } else {
                // If data structure is incorrect, use empty arrays
                console.warn('Invalid data structure received:', data);
                setInfluencerData([]);
                setSamplePages([]);
            }

            setIsLoading(false);
        } catch (err) {
            setError(`Failed to fetch data: ${err.message}`);
            setIsLoading(false);
            console.error('Error fetching data:', err);
            // Set empty arrays on error
            setInfluencerData([]);
            setSamplePages([]);
        }
    };

    // ... rest of the component code remains the same


    const categories = [
        { id: 'cash-hunt', name: 'Cash Hunts', label: 'Cash Hunt', image: 'https://img.icons8.com/color/96/000000/money-bag.png' },
        { id: 'dining', name: 'Dining', label: 'Dining', image: 'https://img.icons8.com/color/48/cafe.png' },

        { id: 'education', name: 'Education', image: 'https://img.icons8.com/color/96/000000/school.png' },
        { id: 'fashion', name: 'Fashion', label: 'Fashion', image: 'https://img.icons8.com/color/96/000000/wardrobe.png' },
        { id: 'fitness', name: 'Fitness', image: 'https://img.icons8.com/color/96/000000/heart-health.png' },
        { id: 'food-blog', name: 'Food Blogs', label: 'Food Blog', image: 'https://img.icons8.com/color/96/000000/dining-room.png' },

        // { id: 'influencer', name: 'Influencers', label: 'Influencer', image: 'https://img.icons8.com/color/96/000000/video-call.png' },


        { id: 'influencer', name: 'Influencers', label: 'Influencer', image: 'https://img.icons8.com/color/96/000000/instagram.png' },

        { id: 'news', name: 'News', image: 'https://img.icons8.com/color/96/000000/news.png' },
        { id: 'real-estate', name: 'Real Estate', label: 'Real Estate', image: 'https://img.icons8.com/color/96/000000/real-estate.png' },
        // { id: 'travel', name: 'Travel', label: 'Travel', image: 'https://img.icons8.com/color/96/000000/suitcase.png' },
        // { id: 'room-rent', name: 'Room Rent', label: 'Room Rent', image: 'https://img.icons8.com/color/96/000000/room.png' },

        // { id: 'animal', name: 'Animals', label: 'Animal', image: 'https://img.icons8.com/color/48/dog.png' },



        { id: 'shop', name: 'Shop', label: 'Shop', image: 'https://img.icons8.com/color/96/000000/shop.png' },

        { id: 'vehicle', name: 'Vehicle', image: 'https://img.icons8.com/color/96/000000/car.png' } // New category


    ];



    // Separate scroll refs for each section
    const creatorScrollRef = useRef(null);
    const collaborationScrollRef = useRef(null);

    // Separate scroll positions for each section
    const [creatorScrollPosition, setCreatorScrollPosition] = useState(0);
    const [collaborationScrollPosition, setCollaborationScrollPosition] = useState(0);

    const [formData, setFormData] = useState({
        businessInfo: {
            pageName: '',
            pageLink: '',
            phoneNumber: '',
            location: '',
            demo1: '',
            demo2: ''
        },
        category: 'cash-hunt',
        selectedPages: [],
        selectedCreators: [], // Separate state for selected creators
        selectedCollaborations: [], // Separate state for selected collaborations

        /*additionalGifts: {
            chocolate: false,
            customGift: false
        }*/
    });




    // const locations = ['Indore', 'Bhopal', 'Ujjain', 'Gwalior'];
    const locations = [
        'Indore'
    ];


    const locationOptions = locations.map(city => ({ value: city, label: city }));


    // Handler for selecting/deselecting creators
    const handleCreatorSelection = (creatorId) => {
        setFormData((prev) => ({
            ...prev,
            selectedCreators: prev.selectedCreators.includes(creatorId)
                ? prev.selectedCreators.filter((id) => id !== creatorId)
                : [...prev.selectedCreators, creatorId],
        }));
    };

// Handler for selecting/deselecting collaborations
    const handleCollaborationSelection = (collaborationId) => {
        setFormData((prev) => ({
            ...prev,
            selectedCollaborations: prev.selectedCollaborations.includes(collaborationId)
                ? prev.selectedCollaborations.filter((id) => id !== collaborationId)
                : [...prev.selectedCollaborations, collaborationId],
        }));
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            businessInfo: {
                ...prev.businessInfo,
                [name]: value
            }
        }));
    };

    const handleLocationChange = (selectedOption) => {
        setFormData(prev => ({
            ...prev,
            businessInfo: {
                ...prev.businessInfo,
                location: selectedOption ? selectedOption.value : ''
            }
        }));
    };

    // Scroll handlers remain the same
    const handleCreatorScroll = () => {
        const container = creatorScrollRef.current;
        if (container) {
            const progress = (container.scrollLeft / (container.scrollWidth - container.clientWidth)) * 100;
            setCreatorScrollPosition(progress);
        }
    };

    const handleCollaborationScroll = () => {
        const container = collaborationScrollRef.current;
        if (container) {
            const progress = (container.scrollLeft / (container.scrollWidth - container.clientWidth)) * 100;
            setCollaborationScrollPosition(progress);
        }
    };

    // Separate scroll functions for each section
    const scrollCreators = (direction) => {
        const container = creatorScrollRef.current;
        if (container) {
            const scrollAmount = direction === 'left' ? -320 : 320;
            container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    const scrollCollaborations = (direction) => {
        const container = collaborationScrollRef.current;
        if (container) {
            const scrollAmount = direction === 'left' ? -320 : 320;
            container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    // Updated filtering logic for influencers
    const filteredInfluencers = influencerData.filter(influencer =>
        // Check both location and category
        (!formData.businessInfo.location || influencer.location === formData.businessInfo.location) &&
        influencer.category === formData.category // Must match selected category
    );

    const filteredPages = samplePages.filter(page =>
        (!formData.businessInfo.location || page.location === formData.businessInfo.location) &&
        (!formData.category || page.category === formData.category)
    );



// Updated category selection handler
    const handleCategoryChange = (categoryId) => {
        setFormData(prev => ({
            ...prev,
            category: categoryId,
            selectedCreators: [], // Reset selected creators when category changes
            selectedCollaborations: [] // Reset selected collaborations when category changes
        }));
    };

// Add these state declarations near your other useState declarations in CollaborationForm
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState('idle');

    // Update your handleSubmit function
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        // Check if required fields are filled
        const missingFields = [];
        if (!formData.businessInfo.pageName) missingFields.push("Page Name");
        if (!formData.businessInfo.pageLink) missingFields.push("Page Link");
        if (!formData.businessInfo.phoneNumber) missingFields.push("Phone Number");

        if (missingFields.length > 0) {
            alert(`Please fill the following fields: ${missingFields.join(', ')}`);
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await fetch(SCRIPT_URL, {
                method: 'POST',
                body: JSON.stringify(formData),
            });

            const result = await response.json();
            if (result.status === 'success') {
                setSubmitStatus('success');
                alert('Form submitted successfully!');
                // Reset form or redirect
            } else {
                setSubmitStatus('error');
                alert('Failed to submit form. Please try again.');
            }
        } catch (err) {
            console.error('Error submitting form:', err);
            setSubmitStatus('error');
            alert('An error occurred. Please try again.');
        } finally {
            setIsSubmitting(false);
            setSubmitStatus('idle');
        }
    };


    const FilterDropdown = ({ onSort, section, currentSort }) => {
        const [isOpen, setIsOpen] = useState(false);

        const sortOptions = [
            { id: 'nameAsc', label: 'Name (A-Z)', value: 'name-asc', icon: '🔼' },
            { id: 'nameDesc', label: 'Name (Z-A)', value: 'name-desc', icon: '🔽' },
            { id: 'followersHigh', label: 'Most Followers', value: 'followers-desc', icon: '📈' },
            { id: 'followersLow', label: 'Least Followers', value: 'followers-asc', icon: '📉' },
            { id: 'chargesHigh', label: 'Price: High to Low', value: 'charges-desc', icon: '💰🔽' },
            { id: 'chargesLow', label: 'Price: Low to High', value: 'charges-asc', icon: '💰🔼' },
        ];

        const currentOption = sortOptions.find(opt => opt.value === currentSort);
        const displayText = currentOption ? currentOption.label : 'Sort By';

        return (
            <div className="relative">
                <button
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        setIsOpen(!isOpen);
                    }}
                    className="flex items-center gap-3 px-5 py-2.5 text-sm bg-white border border-gray-300 text-gray-800 rounded-lg shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-offset-2 min-w-[160px] justify-between"
                >
                    <div className="flex items-center gap-2">
                        <SlidersHorizontal size={16} className="text-purple-500"/>
                        <span className="font-medium">{displayText}</span>
                    </div>
                    <ChevronDown
                        size={16}
                        className={`text-gray-500 ${isOpen ? 'rotate-180' : ''}`}
                    />
                </button>

                {isOpen && (
                    <>
                        <div
                            className="fixed inset-0 z-40"
                            onClick={() => setIsOpen(false)}
                        />
                        <div
                            className="absolute right-0 mt-2 w-60 bg-white border border-gray-200 rounded-xl shadow-xl z-50 py-2">
                            {sortOptions.map((option) => (
                                <button
                                    type="button"
                                    key={option.id}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onSort(option.value);
                                        setIsOpen(false);
                                    }}
                                    className={`w-full px-5 py-3 text-left text-sm flex items-center justify-between
                                hover:bg-purple-100 hover:text-purple-700
                                ${currentSort === option.value ? 'bg-purple-50 text-purple-700 font-semibold' : 'text-gray-700'}`}
                                >
                                    <div className="flex items-center gap-3">
                                        <span className="text-lg">{option.icon}</span>
                                        <span>{option.label}</span>
                                    </div>
                                    {currentSort === option.value && (
                                        <Check size={18} className="text-purple-600"/>
                                    )}
                                </button>
                            ))}
                        </div>
                    </>
                )}
            </div>
        );
    };

    const [creatorSort, setCreatorSort] = useState('');
    const [collaborationSort, setCollaborationSort] = useState('');

    // Updated parseFollowerCount function to handle mixed formats
    const parseFollowerCount = (followers) => {
        if (!followers) return 0;

        // Convert to string and standardize format
        const str = followers.toString().toUpperCase().trim();

        // Handle different formats
        try {
            // Remove any commas and spaces
            const cleanStr = str.replace(/,|\s/g, '');

            // Extract the numeric part and the suffix (K or M)
            const match = cleanStr.match(/^([\d.]+)([KM])?$/);

            if (!match) return 0;

            const [, num, suffix] = match;
            const baseNum = parseFloat(num);

            if (isNaN(baseNum)) return 0;

            // Convert based on suffix
            switch (suffix) {
                case 'K':
                    return baseNum * 1000;
                case 'M':
                    return baseNum * 1000000;
                default:
                    return baseNum;
            }
        } catch (error) {
            console.error('Error parsing follower count:', error);
            return 0;
        }
    };





// Add this sorting function
    const applySort = (data, sortType) => {
        if (!sortType) return data;

        return [...data].sort((a, b) => {
            const [field, direction] = sortType.split('-');
            const multiplier = direction === 'asc' ? 1 : -1;

            switch (field) {
                case 'name':
                    return multiplier * (a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                case 'followers':
                    const followersA = parseFollowerCount(a.followers);
                    const followersB = parseFollowerCount(b.followers);
                    return multiplier * (followersA - followersB);
                case 'charges':
                    return multiplier * (Number(a.charges) - Number(b.charges));
                default:
                    return 0;
            }
        });
    };

// Update your filtering logic to include sorting
    const filteredAndSortedInfluencers = applySort(
        influencerData.filter(influencer =>
            (!formData.businessInfo.location || influencer.location === formData.businessInfo.location) &&
            influencer.category === formData.category
        ),
        creatorSort
    );

    const filteredAndSortedPages = applySort(
        samplePages.filter(page =>
            (!formData.businessInfo.location || page.location === formData.businessInfo.location) &&
            (!formData.category || page.category === formData.category)
        ),
        collaborationSort
    );

    const LoadingSkeleton = React.memo(() => (
        <div className="flex gap-4 px-1">
            {[1, 2, 3, 4].map((i) => (
                <div key={i} className="flex-none w-60 bg-white rounded-xl border border-gray-200 shadow-sm">
                    <div className="flex flex-col items-center pt-5 pb-4 bg-gray-100 rounded-t-xl animate-pulse">
                        <div className="w-16 h-16 rounded-full bg-gray-200" />
                        <div className="h-4 w-20 bg-gray-200 rounded mt-3" />
                        <div className="h-3 w-16 bg-gray-200 rounded mt-2" />
                    </div>
                    <div className="grid grid-cols-2 py-3 border-b border-gray-100">
                        <div className="flex flex-col items-center gap-1">
                            <div className="h-4 w-14 bg-gray-200 rounded" />
                            <div className="h-3 w-10 bg-gray-200 rounded" />
                        </div>
                        <div className="flex flex-col items-center gap-1 border-l border-gray-100">
                            <div className="h-4 w-14 bg-gray-200 rounded" />
                            <div className="h-3 w-10 bg-gray-200 rounded" />
                        </div>
                    </div>
                    <div className="p-3 space-y-2">
                        <div className="h-9 bg-gray-200 rounded-lg" />
                        <div className="h-9 bg-gray-200 rounded-lg" />
                    </div>
                </div>
            ))}
        </div>
    ));


    const CreatorCard = React.memo(({ influencer, onSelect, isSelected }) => {
        // Check if demo links are available
        const isDemo1Available = influencer.demo1 && influencer.demo1.trim() !== '';
        const isDemo2Available = influencer.demo2 && influencer.demo2.trim() !== '';

        const [isLoading, setIsLoading] = useState(false);

        return (
            <div className="flex-none w-60 bg-white rounded-xl border border-gray-300 shadow-md hover:shadow-lg transition-all">
                <div className="flex flex-col items-center pt-4 pb-3 bg-gradient-to-tr from-[#E1306C] via-[#C13584] to-[#833AB4] rounded-t-xl">
                    <div className="w-16 h-16 rounded-full bg-black p-1">
                        <div className="w-full h-full rounded-full bg-white overflow-hidden">
                            <img
                                src={influencer.profilePicture}
                                alt={`${influencer.name}'s profile`}
                                className="w-full h-full object-cover"
                            />
                        </div>
                    </div>
                    <h3 className="mt-3 text-lg font-semibold text-white">{influencer.name}</h3>
                    <div className="flex items-center gap-1 text-white/90">
                        <MapPin size={14} />
                        <span className="text-sm">{influencer.location}</span>
                    </div>
                </div>
                <div className="grid grid-cols-2 py-3 border-b border-gray-200 bg-white">
                    <div className="flex flex-col items-center">
                        <div className="flex items-center gap-1">
                            <Users size={16} className="text-gray-900" />
                            <span className="text-lg font-bold text-gray-900">{influencer.followers}</span>
                        </div>
                        <span className="text-sm text-gray-600">Followers</span>
                    </div>
                    <div className="flex flex-col items-center border-l border-gray-200">
                        <span className="text-lg font-bold text-gray-900">₹{influencer.charges}</span>
                        <span className="text-sm text-gray-600">Per Post</span>
                    </div>
                </div>
                <div className="p-3 space-y-2">
                    <div className="flex justify-between gap-3">
                        <button
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                onSelect(influencer.id);
                            }}
                            className={`w-1/2 py-2 rounded-lg font-medium text-sm transition-all ${
                                isSelected
                                    ? 'bg-gradient-to-r from-purple-600 to-pink-600 text-white'
                                    : 'bg-black text-white hover:bg-gray-800'
                            }`}
                        >
                            {isSelected ? 'Selected' : 'Select'}
                        </button>

                        <button
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                setIsLoading(true);
                                window.open(`https://${influencer.link}`, '_blank');
                                setIsLoading(false);
                            }}
                            disabled={isLoading}
                            className="w-1/2 flex items-center justify-center gap-2 py-2 text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-pink-500 rounded-lg hover:from-purple-600 hover:to-pink-600 transition-all shadow-md"
                        >
                            {isLoading ? (
                                <div className="animate-spin">
                                    <Loader2 size={16} className="text-white" /> {/* Loading icon */}
                                </div>
                            ) : (
                                <>
                                    <ExternalLink size={16} className="text-white" />
                                    <span>View</span>
                                </>
                            )}
                        </button>
                    </div>

                    <div className="flex justify-between gap-3">
                        {/* Demo 1 Button with Custom Icon and Shadow */}
                        <button
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                if (isDemo1Available) window.open(influencer.demo1, '_blank');
                            }}
                            disabled={!isDemo1Available}
                            className={`w-1/2 py-2 rounded-lg font-medium text-sm transition-all flex items-center justify-center gap-2 shadow-sm ${
                                isDemo1Available
                                    ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:from-blue-600 hover:to-blue-700'
                                    : 'bg-gradient-to-r from-gray-100 to-gray-200 text-gray-400 cursor-not-allowed'
                            }`}
                        >
                            {isDemo1Available ? (
                                'Demo 1'
                            ) : (
                                <>
                                    <XCircle className="w-4 h-4 text-gray-500" /> {/* Custom Icon */}
                                    <span className="text-gray-500">Demo 1</span>
                                </>
                            )}
                        </button>

                        {/* Demo 2 Button with Custom Icon and Shadow */}
                        <button
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                if (isDemo2Available) window.open(influencer.demo2, '_blank');
                            }}
                            disabled={!isDemo2Available}
                            className={`w-1/2 py-2 rounded-lg font-medium text-sm transition-all flex items-center justify-center gap-2 shadow-sm ${
                                isDemo2Available
                                    ? 'bg-gradient-to-r from-green-500 to-green-600 text-white hover:from-green-600 hover:to-green-700'
                                    : 'bg-gradient-to-r from-gray-100 to-gray-200 text-gray-400 cursor-not-allowed'
                            }`}
                        >
                            {isDemo2Available ? (
                                'Demo 2'
                            ) : (
                                <>
                                    <XCircle className="w-4 h-4 text-gray-500" /> {/* Custom Icon */}
                                    <span className="text-gray-500">Demo 2</span>
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        );
    });

    const debounceScroll = (callback, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => callback(...args), delay);
        };
    };

    const debouncedHandleScroll = debounceScroll(handleCreatorScroll, 100);


    const CollaborationCard = ({page, onSelect, isSelected}) => {
        // Check if demo links are available
        const isDemo1Available = page.demo1 && page.demo1.trim() !== '';
        const isDemo2Available = page.demo2 && page.demo2.trim() !== '';

        return (
            <div
                className="flex-none w-60 bg-white rounded-xl border border-gray-300 shadow-md hover:shadow-lg transition-all">
                <div
                    className="flex flex-col items-center pt-4 pb-3 bg-gradient-to-tr from-purple-600 via-pink-500 to-pink-600 rounded-t-xl">
                    <div className="w-16 h-16 rounded-full bg-black p-1">
                        <div className="w-full h-full rounded-full bg-white overflow-hidden">
                            <img
                                src={page.profilePicture}
                                alt={`${page.name}'s profile`}
                                className="w-full h-full object-cover"
                            />
                        </div>
                    </div>
                    <h3 className="mt-3 text-lg font-semibold text-white">{page.name}</h3>
                    <div className="flex items-center gap-1 text-white/90">
                        <MapPin size={14}/>
                        <span className="text-sm">{page.location}</span>
                    </div>
                </div>
                <div className="grid grid-cols-2 py-3 border-b border-gray-200 bg-white">
                    <div className="flex flex-col items-center">
                        <div className="flex items-center gap-1">
                            <Users size={16} className="text-gray-900" />
                            <span className="text-lg font-bold text-gray-900">{page.followers}</span>
                        </div>
                        <span className="text-sm text-gray-600">Followers</span>
                    </div>
                    <div className="flex flex-col items-center border-l border-gray-200">
                        <span className="text-lg font-bold text-gray-900">₹{page.charges}</span>
                        <span className="text-sm text-gray-600">Per Post</span>
                    </div>
                </div>
                <div className="p-3 space-y-2">
                    <div className="flex justify-between gap-3">
                        <button
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                onSelect(page.id);
                            }}
                            className={`w-1/2 py-2 rounded-lg font-medium text-sm transition-all ${
                                isSelected
                                    ? 'bg-gradient-to-r from-purple-600 to-pink-600 text-white'
                                    : 'bg-black text-white hover:bg-gray-800'
                            }`}
                        >
                            {isSelected ? 'Selected' : 'Select'}
                        </button>
                        <button
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                setIsLoading(true);
                                window.open(`https://${page.link}`, '_blank');
                                setIsLoading(false);
                            }}
                            disabled={isLoading}
                            className="w-1/2 flex items-center justify-center gap-2 py-2 text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-pink-500 rounded-lg hover:from-purple-600 hover:to-pink-600 transition-all shadow-md"
                        >
                            {isLoading ? (
                                <div className="animate-spin">
                                    <Loader2 size={16} className="text-white"/> {/* Loading icon */}
                                </div>
                            ) : (
                                <>
                                    <ExternalLink size={16} className="text-white"/>
                                    <span>View</span>
                                </>
                            )}
                        </button>
                    </div>
                    {/*<div className="flex justify-between gap-3">
                        <button
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                if (isDemo1Available) window.open(page.demo1, '_blank');
                            }}
                            disabled={!isDemo1Available}
                            className={`w-1/2 py-2 rounded-lg font-medium text-sm transition-all ${
                                isDemo1Available
                                    ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:from-blue-600 hover:to-blue-700'
                                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                            }`}
                        >
                            {isDemo1Available ? 'Demo 1' : 'Link Not Available'}
                        </button>
                        <button
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                if (isDemo2Available) window.open(page.demo2, '_blank');
                            }}
                            disabled={!isDemo2Available}
                            className={`w-1/2 py-2 rounded-lg font-medium text-sm transition-all ${
                                isDemo2Available
                                    ? 'bg-gradient-to-r from-green-500 to-green-600 text-white hover:from-green-600 hover:to-green-700'
                                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                            }`}
                        >
                            {isDemo2Available ? 'Demo 2' : 'Link Not Available'}
                        </button>
                    </div>*/}
                </div>
            </div>
        );
    };



    return (
        <div className="h-screen flex flex-col">
            {/* Navbar */}
            <nav className="sticky top-0  text-white p-4 shadow-md z-10 fixed-navbar ">
                <ul className="flex items-center ">
                    <li className="navbar-item logo">
                        <h1>
                            <a href="/" className="logo-link">
                                <img src="/newlogo.png" alt="DubbiGo Logo" className="logo-image"/>
                                <a>ubbiGo</a>
                            </a>
                        </h1>
                    </li>
                </ul>
            </nav>


            {/* Main Content */}
            <div className="flex-grow overflow-y-auto bg-gradient-to-br from-[#0d314b] via-[#193a55] to-[#1c4b65] p-6">
                <div className="max-w-4xl mx-auto">

                    <div className="text-center mb-8">
                        <h1 className="text-4xl font-bold text-white mb-2"> Promotion Form</h1>
                        <p className="text-xl text-gray-200">Connect with City Influencers</p>
                    </div>

                    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                        {/* Business Information Section */}
                        <div className="bg-gradient-to-r from-[#4b0d49] to-[#e310cb] p-6 text-white">
                            <h2 className="text-2xl font-semibold">Page Information</h2>
                            <p className="mt-1 text-blue-100">Tell us about your page</p>
                        </div>

                        <form onSubmit={handleSubmit} className="p-6 space-y-6" noValidate>
                            {/* Business Info Fields */}
                            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                                <div>
                                    <label htmlFor="pageName" className="block text-sm font-medium text-gray-700 mb-1 ">
                                        Page Name
                                    </label>
                                    <input
                                        type="text"
                                        id="pageName"
                                        name="pageName"
                                        value={formData.businessInfo.pageName}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#e310cb] focus:ring-[#e310cb] text-gray-900"
                                        placeholder="Your Page Name"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="pageLink" className="block text-sm font-medium text-gray-700 mb-1">
                                        Page Link
                                    </label>
                                    <input
                                        type="text"
                                        id="pageLink"
                                        name="pageLink"
                                        value={formData.businessInfo.pageLink}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#e310cb] focus:ring-[#e310cb] text-gray-900"
                                        placeholder="instagram.com/yourpage"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="phoneNumber"
                                           className="block text-sm font-medium text-gray-700 mb-1">
                                        Phone Number
                                    </label>
                                    <input
                                        type="tel"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        value={formData.businessInfo.phoneNumber}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#e310cb] focus:ring-[#e310cb] text-gray-900"
                                        placeholder="+91 1234567890"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="location"
                                           className="block text-sm font-medium text-gray-700 mb-1">
                                        Location
                                    </label>
                                    <Select
                                        id="location"
                                        name="location"
                                        value={locationOptions.find(option => option.value === formData.businessInfo.location)}
                                        onChange={handleLocationChange}
                                        options={locationOptions}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-white text-gray-900 focus:border-[#e310cb] focus:ring-[#e310cb] text-base py-2"
                                        placeholder="Select City"
                                        isClearable
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                minHeight: '32px',
                                                padding: '0 0px',
                                                fontSize: '16px',
                                            }),
                                        }}
                                    />
                                </div>
                            </div>


                                {/* Updated Category Selection */}
                                <div className="mt-8">
                                    <h3 className="text-lg font-semibold text-gray-900 mb-4">Select Category</h3>
                                    <div className="grid grid-cols-3 md:grid-cols-6 gap-4">
                                        {categories.map(category => (
                                            <div
                                                key={category.id}
                                                className={`relative flex flex-col items-center justify-center p-3 rounded-lg border-2 transition-all duration-300 cursor-pointer text-center ${
                                                    formData.category === category.id
                                                        ? 'border-[#e310cb] bg-purple-50'
                                                        : 'border-gray-200 bg-white hover:border-[#e310cb]'
                                                }`}
                                                onClick={() => handleCategoryChange(category.id)}
                                            >
                                                <img
                                                    src={category.image}
                                                    alt={category.name}
                                                    className="w-12 h-12 object-contain mb-2"
                                                />
                                                <span className={`text-sm font-medium ${
                                                    formData.category === category.id ? 'text-[#e310cb]' : 'text-gray-900'
                                                }`}>
                                            {category.name}
                                        </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>



                            /* Content Creator Section */
                            <div className="relative space-y-4">
                                <div className="flex items-center justify-between mb-4">
                                    <h2 className="text-lg font-semibold text-gray-900">Content Creators</h2>
                                    <div className="flex items-center gap-3">
                                        <div className="w-24 h-1 bg-gray-200 rounded-full overflow-hidden">
                                            <div
                                                className="h-full bg-gradient-to-r from-[#E1306C] to-[#C13584] rounded-full transition-all duration-300"
                                                style={{ width: `${creatorScrollPosition}%` }}
                                            />
                                        </div>
                                        <FilterDropdown onSort={setCreatorSort} currentSort={creatorSort} />
                                    </div>
                                </div>
                                <div className="relative group">
                                    {isLoading ? (
                                        <LoadingSkeleton />
                                    ) : filteredInfluencers.length === 0 ? (
                                        <div className="text-center text-gray-500 italic">
                                            No content creators available for this category in the selected location.
                                        </div>
                                    ) : (
                                        <>
                                            <button
                                                type="button"
                                                onClick={() => scrollCreators('left')}
                                                className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-3 z-10 p-2 bg-white shadow-md rounded-full hover:bg-gray-50 transition-opacity"
                                            >
                                                <svg
                                                    className="w-4 h-4 text-gray-600"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                                </svg>
                                            </button>
                                            <div
                                                ref={creatorScrollRef}
                                                onScroll={debouncedHandleScroll}
                                                className="flex gap-4 overflow-x-auto no-scrollbar pb-2 px-1"
                                                style={{ scrollBehavior: 'smooth' }}
                                            >
                                                {filteredAndSortedInfluencers.map((influencer) => (
                                                    <CreatorCard
                                                        key={influencer.id}
                                                        influencer={influencer}
                                                        onSelect={handleCreatorSelection}
                                                        isSelected={formData.selectedCreators.includes(influencer.id)}
                                                    />
                                                ))}
                                            </div>
                                            <button
                                                type="button"
                                                onClick={() => scrollCreators('right')}
                                                className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-3 z-10 p-2 bg-white shadow-md rounded-full hover:bg-gray-50 transition-opacity"
                                            >
                                                <svg
                                                    className="w-4 h-4 text-gray-600"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                                </svg>
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>

                            /* Collaboration Section */
                            <div className="relative space-y-4">
                                <div className="flex items-center justify-between mb-4">
                                    <h2 className="text-lg font-semibold text-gray-900">Collaboration</h2>
                                    <div className="flex items-center gap-3">
                                        <div className="w-24 h-1 bg-gray-200 rounded-full overflow-hidden">
                                            <div
                                                className="h-full bg-gradient-to-r from-purple-600 to-pink-600 rounded-full transition-all duration-300"
                                                style={{ width: `${collaborationScrollPosition}%` }}
                                            />
                                        </div>
                                        <FilterDropdown onSort={setCollaborationSort} currentSort={collaborationSort} />
                                    </div>
                                </div>
                                <div className="relative group">
                                    {isLoading ? (
                                        <LoadingSkeleton />
                                    ) : filteredPages.length === 0 ? (
                                        <div className="text-center text-gray-500 italic">
                                            No collaborations available for this category in the selected location.
                                        </div>
                                    ) : (
                                        <>
                                            <button
                                                type="button"
                                                onClick={() => scrollCollaborations('left')}
                                                className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-3 z-10 p-2 bg-white shadow-md rounded-full hover:bg-gray-50 transition-opacity"
                                            >
                                                <svg
                                                    className="w-4 h-4 text-gray-600"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                                </svg>
                                            </button>
                                            <div
                                                ref={collaborationScrollRef}
                                                onScroll={handleCollaborationScroll}
                                                className="flex gap-4 overflow-x-auto no-scrollbar pb-2 px-1"
                                            >
                                                {filteredAndSortedPages.map((page) => (
                                                    <CollaborationCard
                                                        key={page.id}
                                                        page={page}
                                                        onSelect={handleCollaborationSelection}
                                                        isSelected={formData.selectedCollaborations.includes(page.id)}
                                                    />
                                                ))}
                                            </div>
                                            <button
                                                type="button"
                                                onClick={() => scrollCollaborations('right')}
                                                className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-3 z-10 p-2 bg-white shadow-md rounded-full hover:bg-gray-50 transition-opacity"
                                            >
                                                <svg
                                                    className="w-4 h-4 text-gray-600"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                                </svg>
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>


                            {/* Replace the existing submit button with this */}
                            // Update the SubmitSection component props
                            <SubmitSection
                                selectedCreators={formData.selectedCreators}
                                selectedCollaborations={formData.selectedCollaborations}
                                influencerData={influencerData}
                                samplePages={samplePages}
                                isSubmitting={isSubmitting}
                                submitStatus={submitStatus}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CollaborationForm;